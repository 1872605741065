import { MatSidenav } from "@angular/material/sidenav";
import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "src/services/authentication.service";
import { PersonProvider } from "src/providers/person.provider";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "exity-dashboard";

  @ViewChild("sidenav") sidenav: MatSidenav;

  showNavbar = false;

  constructor(
    private authenticationService: AuthenticationService,
    private personProvider: PersonProvider
  ) {}

  ngOnInit() {
    
    this.authenticationService.isAuthenticated.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.personProvider.get("me", { include: "roles" }).subscribe((me) => {
          if (me) {
            this.showNavbar = true;
            this.authenticationService.setPerson(me);
          }
        });
      } else this.showNavbar = false;
    });
  }
}
