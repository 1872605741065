import { HeaderService, Toggle } from 'src/services/header.service';
import { Component, OnInit } from '@angular/core';
import { HeaderOptions } from 'src/services/header.service';
import { Router } from '@angular/router';
import { LanguageProvider } from 'src/providers/language.provider';
import { Language } from 'src/models/language.model';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  options: HeaderOptions = {};

  languages: Language[] = [];
  checkedLang: number;

  constructor(
    private headerService: HeaderService,
    private router: Router,
    private languageProvider: LanguageProvider
  ) { }

  ngOnInit() {
    // this.checkedLang = 1;

    this.headerService.optionsService.subscribe(options => {
      this.options = options;
      if(this.options && this.options.langToggle) this.handleToggle(this.options.langToggle);
    });

    this.headerService.buttonService.subscribe(buttons => {
      if(this.options) this.options.buttons = buttons;
    });

    this.headerService.toggleService.subscribe(toggle => {
      if(this.options) this.options.langToggle = toggle;
      if(toggle) this.handleToggle(toggle);
    });

    this.headerService.toggleValueService.subscribe(toggleValue => {
      if(toggleValue) this.checkedLang = toggleValue;
    });
  }

  private handleToggle(toggle: Toggle)  {
    if(toggle && toggle.canAdd) {
      const filter = { include: { relation: 'trackLanguages', scope: { where: { idTrack: toggle.track } } } };
      this.languageProvider.all(filter).subscribe(languages => {
        this.languages = languages;
        this.checkedLang = languages.find(l => !!l.trackLanguages.length).id;
      });
    } else if(toggle && !toggle.canAdd) {
      const filter = { include: { relation: 'trackLanguages', scope: { where: { idTrack: toggle.track } } } };
      this.languageProvider.all(filter).subscribe(languages => {
        this.languages = languages.filter(l => !!l.trackLanguages.length);
        this.checkedLang = this.languages[0]?.id;
      });
    }
  }

  onClickBack() {
    const queryParams = {
      ...this.options.back.params,
      backUrl : this.options.back.path,
      backTab : this.options.back.params && this.options.back.params.tab ? this.options.back.params.tab.slice(0, this.options.back.params.tab.length - 1) : []
    };

    if(!queryParams.backTab.length) delete queryParams.backTab;
    if(!queryParams.backUrl.length) delete queryParams.backUrl;

    this.router.navigate([typeof this.options.back.path === 'string' ? this.options.back.path : this.options.back.path.pop()], { queryParams: queryParams });
  }

  onChangeLanguage($event: MatButtonToggleChange) {
    this.options.langToggle.action($event.value, this.checkedLang);
    this.checkedLang = $event.value;
  }
}
