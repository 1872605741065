<header>
    <img src="assets/logos/Imagotipo-white.png" alt="imagotipo">
</header>
<!-- menu start -->
<mat-nav-list>
    <div *ngIf="isAdmin">
        <mat-list-item [routerLink]="homeLink.path" routerLinkActive="active-link">
            <mat-icon>{{ homeLink.icon }}</mat-icon>
            <span>{{ homeLink.name }}</span>
        </mat-list-item>
        <span class="nav-group">JUEGO</span>
        <mat-list-item *ngFor="let link of gameLinks" [routerLink]="link.path" routerLinkActive="active-link">
            <mat-icon>{{ link.icon }}</mat-icon>
            <span>{{ link.name }}</span>
        </mat-list-item>
        <span class="nav-group">OTROS</span>
        <mat-list-item *ngFor="let link of otherLinks" [routerLink]="link.path" routerLinkActive="active-link">
            <mat-icon>{{ link.icon }}</mat-icon>
            <span>{{ link.name }}</span>
        </mat-list-item>
    </div>
    <div *ngIf="isCustomer">
        <span class="nav-group">CLIENTE</span>
        <mat-list-item *ngFor="let link of clientLinks" [routerLink]="link.path" routerLinkActive="active-link">
            <mat-icon>{{ link.icon }}</mat-icon>
            <span>{{ link.name }}</span>
        </mat-list-item>
    </div>
</mat-nav-list>
<!-- menu end -->
<footer>
    <mat-nav-list>
        <mat-list-item (click)="onLogout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Cerrar sesión</span>
        </mat-list-item>
    </mat-nav-list>
</footer>