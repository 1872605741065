import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Filter } from 'src/utils/filter.util';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/services/authentication.service';
import { Language } from 'src/models/language.model';

@Injectable({ providedIn: 'root' })
export class LanguageProvider {

    key = 'Authorization';

    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService
    ) { }

    all(filter?: Filter): Observable<Language[]> {
        const query = filter ? `${environment.serverUrl}/languages?filter=${JSON.stringify(filter)}` : `${environment.serverUrl}/languages`;
        return this.httpClient.get<Language[]>(query, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }
}
