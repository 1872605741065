<mat-toolbar *ngIf="options" class="sticky">
    <button class="back" [ngStyle]="{'margin-bottom': options.css?.marginover ? '24px' : '0px' }"
            *ngIf="options.back" mat-icon-button (click)="onClickBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="text" [ngStyle]="{'margin-bottom': options.css?.marginover ? '24px' : '0px' }">
        <p class="title-overflow-ellipsis">{{options.title}}</p>
        <p class="subtitle">{{options.subtitle}}</p>
    </div>
    <span class="spacer"></span>
    <!-- here would go a searchbar -->
    <mat-button-toggle-group *ngIf="options.langToggle" name="languages" (change)="onChangeLanguage($event)"
        [ngStyle]="{'margin-bottom': options.css?.marginover ? '24px' : '0px' }">
        <mat-button-toggle *ngFor="let lang of languages" [value]="lang.id" [checked]="lang.id === checkedLang">
            {{lang.name.substring(3)}}
        </mat-button-toggle>
    </mat-button-toggle-group>
    <!-- onclick="this.blur()" to evade focusing after close dialog -->
    <button *ngFor="let button of options.buttons" class="raised" mat-raised-button onclick="this.blur()"
        (click)="button.action()" [disabled]="button.disabled" [color]="button.name === 'Borrar' ? 'warn' : 'basic'"
        [ngStyle]="{'margin-bottom': options.css?.marginover ? '24px' : '0px' }">{{button.name}}</button>
</mat-toolbar>