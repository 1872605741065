import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PersonProvider } from 'src/providers/person.provider';
import { AuthenticationService } from 'src/services/authentication.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ROLES } from 'src/utils/constants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  homeLink = {
    icon: 'home',
    name: 'Inicio',
    path: '/home'
  };

  gameLinks = [
    {
      icon: 'near_me',
      name: 'Rutas',
      path: '/tracks'
    },
    {
      icon: 'business_center',
      name: 'Listado de clientes',
      path: '/manage-customer'
    },
    {
      icon: 'group',
      name: 'Listado de Exiters',
      path: '/client-list'
    },
    {
      icon: 'list',
      name: 'Ventas',
      path: '/sales'
    },
  ];

  otherLinks = [
    {
      icon: 'location_on',
      name: 'Localizaciones',
      path: '/locations'
    },
    {
      icon: 'location_searching',
      name: 'Puntos de ruta',
      path: '/points'
    },
    {
      icon: 'search',
      name: 'Acertijos',
      path: '/riddles'
    },
    {
      icon: 'emoji_objects',
      name: 'Pistas',
      path: '/hints'
    },
  ];

  clientLinks = [
    {
      icon: 'home',
      name: 'Inicio',
      path: '/customer-home'
    },
    {
      icon: 'near_me',
      name: 'Rutas',
      path: '/city-tracks'
    },
    {
      icon: 'lock',
      name: 'Datos Creador',
      path: '/creator'
    },
    {
      icon: 'group',
      name: 'Listado de Exiters',
      path: '/client-list'
    },
    {
      icon: 'lock',
      name: 'Cambiar contraseña',
      path: '/change-password'
    },
  ];

  constructor(
    private personProvider: PersonProvider,
    private authenticationService: AuthenticationService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
  
  public isAdmin: boolean = false;
  public isCustomer: boolean = false;
 
  ngOnInit() {
    this.checkAdmin();
  }

  private checkAdmin() {
        if(this.authenticationService.getPerson().roles[0].name === ROLES.ADMIN) {
          this.isAdmin = true;
        }else{
          this.isCustomer=true;
        } 
        
       
        // this.personProvider.get('me', { include: "roles" }).subscribe((me) => {
        //   const [role] = me.roles;
        //   this.authenticationService.setPerson(me);

        //   if (role.name === ROLES.ADMIN)
        //   this.isAdmin = true;
        //   if (role.name === ROLES.CUSTOMER)
        //   this.isCustomer=true;
        // });
      

  }

  onLogout() {
    this.personProvider.logout().subscribe(() => {
      this.authenticationService.removeToken();
      this.authenticationService.unsetPerson()

      this.router.navigate([`/login`], { replaceUrl: true });
    }, err => {
      this.snackBar.open(
        'Ha ocurrido un error, vuelve a intentarlo más tarde',
        'Vale',
        { duration: 5000, verticalPosition: 'top', horizontalPosition: 'center' }
      );
    });
  }
}
