import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Person } from 'src/models/person.model';
import { Filter } from 'src/utils/filter.util';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/services/authentication.service';

@Injectable({ providedIn: 'root' })
export class PersonProvider {

    key = 'Authorization';

    constructor(
        private httpClient: HttpClient,
        private authenticationService: AuthenticationService
    ) { }

    login(email: string, password: string): Observable<any> {
        const body = { email: email, password: password };
        return this.httpClient.post(`${environment.serverUrl}/people/login/admin`, body);
    }

    logout(): Observable<any> {
        const query = `${environment.serverUrl}/people/logout`;
        return this.httpClient.post(query, null, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }

    resetPassword(token: string, password: string): Observable<{ success: boolean }> {
        const query = `${environment.serverUrl}/people/oauth/resetPassword`;
        return this.httpClient.post<{ success: boolean }>(query, { password }, { params: { t: token } });
    }

    forgottenPassword(email: string): Observable<{ success: boolean }> {
        const query = `${environment.serverUrl}/people/oauth/forgotPassword`;
        return this.httpClient.post<{ success: boolean }>(query, { email: email.toLowerCase() });
    }

    get(id: number | string, filter?: Filter): Observable<Person> {
        const query = filter ? `${environment.serverUrl}/people/${id}?filter=${JSON.stringify(filter)}` : `${environment.serverUrl}/people/${id}`;
        return this.httpClient.get<Person>(query, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }

    count(where?: any): Observable<any> {
        const query = where ? `${environment.serverUrl}/people/count?where=${JSON.stringify(where)}` : `${environment.serverUrl}/people/count`;
        return this.httpClient.get(query, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }

    all(filter?: Filter): Observable<Person[]> {
        const query = filter ? `${environment.serverUrl}/people?filter=${JSON.stringify(filter)}` : `${environment.serverUrl}/people`;
        return this.httpClient.get<Person[]>(query, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }

    post(person: Person): Observable<Person> {
        const query = `${environment.serverUrl}/people`;
        return this.httpClient.post<Person>(query, person, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }

    put(id: number | string, person: Person): Observable<Person> {
        const query = `${environment.serverUrl}/people/${id}`;
        return this.httpClient.put<Person>(query, person, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }

    patch(id: number | string, person: Person): Observable<Person> {
        const query = `${environment.serverUrl}/people/${id}`;
        return this.httpClient.patch<Person>(query, person, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }

    delete(id: number | string): Observable<Person> {
        const query = `${environment.serverUrl}/people/${id}`;
        return this.httpClient.delete(query, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }

    changePassword(person: Person): Observable<Person> {
        const query = `${environment.serverUrl}/people/reset-password`;
        return this.httpClient.post<Person>(query, person, { headers: { 'Authorization': this.authenticationService.getToken() } });
    }

}
