import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { PersonProvider } from "src/providers/person.provider";
import { AuthenticationService } from "src/services/authentication.service";
import { ROLES } from "src/utils/constants";

@Injectable({ providedIn: "root" })
export class AuthenticationGuard implements CanActivate {
  private role: "admin" | "customer";

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private personProvider: PersonProvider
  ) {
    this.role = this.authenticationService.getPerson()?.roles[0].name;
  }

  canActivate(): boolean | Observable<boolean> {
    //if there's a token, continue
    const token = this.authenticationService.getToken();

    if (token) return true;

    //if not, checking if there´s is a user to read from the ddbb

    let observable;
    observable = new Observable((subscriber) => {
      if (this.authenticationService.isAuthenticated.value) {
        this.personProvider.get("me", { include: "roles" }).subscribe(
          (me) => {
            me.roles.forEach((role) => {
              if (role.name === "admin" || role.name === "customer")
                subscriber.next(true);
            });
          },
          (error) => {
            this.authenticationService.removeToken();
            this.router.navigate(["/login"], { replaceUrl: true });
            subscriber.next(false);
          }
        );
      } else {
        this.router.navigate(["/login"], { replaceUrl: true });
        subscriber.next(false);
      }
    });

    return observable;
  }
}
