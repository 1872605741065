import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface Button {
  name: string;
  disabled?: boolean;
  action: () => void;
}

export interface Toggle {
  action: (langId: number, previousLangId: number) => void;
  track?: number;
  canAdd?: boolean;
}

export interface HeaderOptions {
  title?: string;
  subtitle?: string;
  back?: { path: string[], params?: any };
  buttons?: Button[];
  langToggle?: Toggle;
  css?: { marginover?: boolean };
}

@Injectable({ providedIn: 'root' })
export class HeaderService {

  optionsService = new BehaviorSubject(null);
  buttonService = new BehaviorSubject(null);
  toggleService = new BehaviorSubject(null);
  toggleValueService = new BehaviorSubject(null);

  constructor() { }

  setOptions(options: HeaderOptions) {
    this.optionsService.next(options);
  }

  setButtons(buttons: Button[]) {
    this.buttonService.next(buttons);
  }

  setToggle(toggle: Toggle) {
    this.toggleService.next(toggle);
  }

  setToggleValue(value: number) {
    this.toggleValueService.next(value);
  }
}
