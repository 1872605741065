import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Person } from "src/models/person.model";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  isAuthenticated = new BehaviorSubject(false);
  key: string;
  keyRole: string;
  person: Person;

  constructor() {
    this.key = environment.credentialsKey;
    this.keyRole = "role";
    if (this.getToken()) this.isAuthenticated.next(true);
  }

  getToken() {
    return localStorage.getItem(this.key)
      ? "" + localStorage.getItem(this.key)
      : null;
  }

  setToken(token) {
    localStorage.setItem(this.key, token);
    this.isAuthenticated.next(true);
  }

  removeToken() {
    localStorage.removeItem(this.key);
    this.isAuthenticated.next(false);
  }

  setPerson(person: Person) {
    this.person = person;
  }

  unsetPerson() {
    return {};
  }
  getPerson() {
    return this.person;
  }
}
