import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from 'src/guards/authentication.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/content/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'tracks',
    loadChildren: () => import('./pages/content/tracks/tracks.module').then(m => m.TracksModule),
    canActivate:[AuthenticationGuard],
  },
  {
    path: 'points',
    loadChildren: () => import('./pages/content/points/points.module').then(m => m.PointsModule)
  },
  {
    path: 'riddles',
    loadChildren: () => import('./pages/content/riddles/riddles.module').then(m => m.RiddlesModule)
  },
  {
    path: 'hints',
    loadChildren: () => import('./pages/content/hints/hints.module').then(m => m.HintsModule)
  },
  {
    path: 'manage-customer',
    loadChildren: () => import('./pages/content/manage-customer/manage-customer.module').then(m => m.ManageCustomerModule)
  },
  {
    path: 'locations',
    loadChildren: () => import('./pages/content/locations/locations.module').then(m => m.LocationsModule)
  },
  //Customer
  {
    path:'creator', 
    loadChildren: () => import('./pages/content/customer/creator/creator.module').then(m => m.CreatorModule)
  },
  {
    path: 'city-tracks',
    loadChildren: () => import('./pages/content/customer/city-tracks/city-tracks.module').then(m => m.CityTracksModule)
  },
  {
    path: 'customer-home',
    loadChildren: () => import('./pages/content/customer/customer-home/customer-home.module').then(m => m.CustomerHomeModule)
  },
  {
    path: 'client-list',
    loadChildren: () => import('./pages/content/customer/client-list/client-list.module').then(m => m.ClientListModule)
  },
  {
    path: 'sales',
    loadChildren: () => import('./pages/content/sales/sales.module').then(m => m.SalesModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/content/customer/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  {
    path: 'redirect-purchase/person/:personId/track/:trackId/price/:price/token/:token',
    loadChildren: () => import('./pages/mobile-app/redirect-purchase/redirect-purchase.module').then(m => m.RedirectPurchaseModule)
  },
  {
    path: 'congrats',
    loadChildren: () => import('./pages/mobile-app/congrats/congrats.module').then(m => m.CongratsModule)
  },
  {
    path: 'error-purchase',
    loadChildren: () => import('./pages/mobile-app/error-purchase/error-purchase.module').then(m => m.ErrorPurchaseModule)
  },
  {
    path: 'error-not-mobile',
    loadChildren: () => import('./pages/mobile-app/error-not-mobile/error-not-mobile.module').then(m => m.ErrorNotMobileModule)
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
