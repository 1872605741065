<mat-sidenav-container *ngIf="showNavbar">
    <mat-sidenav #sidenav mode="side" opened="true">
        <app-navbar></app-navbar>
    </mat-sidenav>
        <ng-container *ngTemplateOutlet="template"></ng-container>
</mat-sidenav-container>

<div *ngIf="!showNavbar">
    <ng-container *ngTemplateOutlet="template"></ng-container>
</div>

<ng-template #template>
    <app-header></app-header>
    <router-outlet></router-outlet>
</ng-template>
